import toast from "react-hot-toast";
import {InvalidAuthException} from "@app/utils/InvalidAuthException";

export function accessibleRouteChangeHandler() {
  return window.setTimeout(() => {
    const mainContainer = document.getElementById("primary-app-container");
    if (mainContainer) {
      mainContainer.focus();
    }
  }, 50);
}

export function getBearerToken() {
  const tokenString = localStorage.getItem('token');
  if (tokenString != null && tokenString != "undefined") {
    return tokenString;
  }
  return null
}


export async function getOrganizations(){
  return fetch(process.env["API_URL"] + '/profiles', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getBearerToken()
    }
  }).then(response => {return parseResponse(response)});
}

export async function getUserInfo(){
  return fetch(process.env["API_URL"] + '/users/me', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getBearerToken()
    }
  }).then(response => {return parseResponse(response)});
}

export async function getAds(){
  return fetch(process.env["API_URL"] + '/ads', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getBearerToken()
    }
  }).then(response => {return parseResponse(response)});
}

export async function getAdsByOrganizationId(id){
  return fetch(process.env["API_URL"] + '/ads?organizationId=' + id, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getBearerToken()
    }
  }).then(response => {return parseResponse(response)});
}

export async function getWallet(){
  return fetch(process.env["API_URL"] + '/wallet', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getBearerToken()
    }
  }).then(response => {return parseResponse(response)});
}

export async function addPaymentMethodToWallet(payload){
  return fetch(process.env["API_URL"] + '/wallet/payment-method-intent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getBearerToken()
    },
    body: JSON.stringify(payload)
  }).then(response => {return parseResponse(response)});
}

export async function removePaymentMethodFromWallet(payload){
  return fetch(process.env["API_URL"] + '/wallet/payment-method', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getBearerToken()
    },
    body: JSON.stringify(payload)
  }).then(response => {return response});
}

export async function setupWallet(payload){
  return fetch(process.env["API_URL"] + '/wallet', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getBearerToken()
    },
    body: JSON.stringify(payload)
  }).then(response => {return parseResponse(response)});
}

export async function getCampaigns(){
  return fetch(process.env["API_URL"] + '/campaigns', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getBearerToken()
    }
  }).then(response => {return parseResponse(response)});
}


export async function createAd(payload) {
  return fetch(process.env["API_URL"] + '/ads', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getBearerToken()
    },
    body: JSON.stringify(payload)
  }).then(data => {
    toast("Successfully created Ad!");
    return parseResponse(data);
  })
}

export async function createAccount(payload) {
  return fetch(process.env["API_URL"] + '/auth/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getBearerToken()
    },
    body: JSON.stringify(payload)
  }).then(data => {
    toast("Successfully created account!");
    return data;
  })
}

export async function registerOrganization(payload) {
  return fetch(process.env["API_URL"] + '/organizations', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getBearerToken()
    },
    body: JSON.stringify(payload)
  }).then(data => {
    toast("Successfully registered Organization!");
    return parseResponse(data);
  })
}

export async function loginUser(credentials) {
  return fetch(process.env["API_URL"] + '/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  }).then(data => {
    return parseAuthResponse(data);
  })
}

export async function createCampaign(payload){
  return fetch(process.env["API_URL"]+ '/campaigns', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getBearerToken()
    },
    body: JSON.stringify(payload)
  }).then(data => {
    return parseResponse(data);
  })
}

export async function getPresignedUrl(orgId, adId, name){
  const url = process.env["API_URL"]+ '/ads/' + adId + '/uploadUrl';
  const payload = {
    "name": name,
    "orgId": orgId
  };

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getBearerToken()
    },
    body: JSON.stringify(payload)
  }).then(data => {
    return parseResponse(data);
  })
}

export async function uploadToS3(presignedUrl, file){
  
  return fetch(presignedUrl, {
    body: file,
    headers: {
      'Content-Length': file.length,
      'Content-Type': file.type
    },
    method: 'PUT'
  });
}
function parseResponse(response) {
   if (response.ok){
      return response.json();
    } else if (response.status === 401) {
     logout();
      return response.json();
    }
}

function parseAuthResponse(response) {
  if (response.ok){
    return response.json();
  } else if (response.status == 401) {
    throw new InvalidAuthException("asdf")
  }
}




export const NullComponent= () => {
  return null;
};

export const logout = () => {
  localStorage.clear();
  window.location.reload(false);
}