import {Card, CardBody, CardFooter, CardHeader, Gallery} from "@patternfly/react-core";
import * as React from "react";
import {useEffect} from "react";
import {getAds, getAdsByOrganizationId} from "@app/utils/utils";

interface IChooseAdComponentProps {
    setAdId: Function;
    adId: string;
    orgId: String;
}

const ChooseAdComponent: React.FunctionComponent<IChooseAdComponentProps> = (props) => {

    const [ads, setAds] = React.useState();

    // load all organization into `state`
    useEffect(() =>
    {
        getAdsByOrganizationId(props.orgId).then(ads => {
            setAds(ads);
        })
    }, []);

    const saveAdId = e => {
        props.setAdId(e.currentTarget.getAttribute('data-ad-id'));
    };

    const isSelected = adId => {
        return (props.adId == adId) ? true : false;
    };

    const cardFlip = (data) => {
        console.log(data);
    };

    function getListOfSimpleCards() {

        if (ads == null || ads == undefined){
            return <></>;
        }
        return ads.map(data => {
            return SimpleCard(data)
        })
    }

    const SimpleCard = (data) => (
        <span>
            <Card key={data.adId} data-ad-id={data.adId} isSelectableRaised isSelected={isSelected(data.adId)} isCompact={true} isHoverable={true} onClick={saveAdId}>
                <CardHeader>{data.name}</CardHeader>
                <CardBody>{data.organizationName}</CardBody>
                <CardFooter>{data.footer}</CardFooter>
            </Card>
            <br />
        </span>
    );

    return (
        <React.Fragment>
            {getListOfSimpleCards()}
        </React.Fragment>
    )
};
export {ChooseAdComponent}