import * as React from 'react';
import '@app/Organizations/organizations.css';
import {
    Button,
    Modal, ModalVariant,
    TextInput,
    Wizard
} from '@patternfly/react-core';
import {useState} from "react";
import {CampaignTypesComponent} from "@app/Campaigns/CampaignTypeComponent";
import {ChooseAdComponent} from "@app/Campaigns/ChooseAdComponent";
import {ChooseOrganizationComponent} from "@app/Campaigns/ChooseOrganizationComponent";
import {CampaignDatePicker} from "@app/Campaigns/CampaignDatePicker";
import {createCampaign} from "@app/utils/utils";


export interface ICreateCampaignProps {
    triggerRefresh: Function;
}

const SetNameComponent = (props) => {
    return (
        <TextInput value={props.name} onChange={props.setName} aria-label={"Set Name of Campaign"}/>
    );
}

const CreateCampaign: React.FunctionComponent<ICreateCampaignProps> = (props) => {

    const [isModalOpen, setModalOpen] = React.useState(false);
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [ad_id, setAdId] = useState('');
    const [org_id, setOrgId] = useState('');
    const [configMap, setConfigMap] = useState({});

    const organizationNotSetDisplay = () => {
        return <h1>Please choose an Organization in Step 2</h1>;
    }

    const nameNotSetDispplay = () => {
        return <h1>Please set name of campaign in Step 1</h1>;
    }

    const resetForm = () => {
        setName('');
        setType('');
        setAdId('');
        setOrgId('');
        setConfigMap({});
    }

    const ChooseAdComponentStep = (props) => {
        if (org_id == undefined || org_id == null || org_id==""){
            return organizationNotSetDisplay();
        }
        else {
            return <ChooseAdComponent orgId={org_id} adId={ad_id} setAdId={setAdId}/>;
        }
    }

    const ReviewData = () => {

        if (name == undefined || name == null || name == ""){
            return nameNotSetDispplay();
        }
        return (<React.Fragment>
                <h1>Name: {name}</h1>
            </React.Fragment>)
    }

    const steps = [
        { name: 'Set Name', component: <p><SetNameComponent name={name} setName={setName}/></p>, onNext: ''},
        { name: 'Choose Organization', component: <p><ChooseOrganizationComponent chosenOrganizationId={org_id} setChosenOrganizationId={setOrgId}/></p>, onNext: ''},
        { name: 'Campaign Type', component: <CampaignTypesComponent setCampaignType={setType} campaign={type}/> },
        { name: 'Choose Ads', component: <ChooseAdComponentStep /> },
        { name: 'Choose Time', component: <CampaignDatePicker/> },
        { name: 'Review', component: <ReviewData/>, nextButtonText: 'Submit Campaign Request' }
    ];

    const handleModalToggle = () => {
        resetForm();
        setModalOpen(!isModalOpen);
    };

    const handleSubmit = () => {
        createCampaign({
                "name": name,
                "type": type,
                "ads": [ad_id],
                "config": configMap
            }
        ).then(data => {
            props.triggerRefresh();
            handleModalToggle()
        })
    }

    return (
            <React.Fragment>
                <Button variant="primary" onClick={handleModalToggle}>
                    Create New Campaign
                </Button>
                <Modal
                    variant={ModalVariant.large}
                    title="Create a New Ad Campaign"
                    description="Enter information about your organization"
                    isOpen={isModalOpen}
                    onClose={handleModalToggle}
                >
                    <Wizard navAriaLabel={"Create Ad Campaign steps"}
                            mainAriaLabel={"Create Ad Campaign steps content"}
                            steps={steps} height={400} onClose={handleModalToggle} onSave={handleSubmit}
                    />
                </Modal>
            </React.Fragment>
    );
}

export { CreateCampaign };