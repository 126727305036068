import * as React from 'react';
import '@app/Organizations/organizations.css';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader, Form, FormAlert,
    Gallery,
    Modal, ModalVariant,
    PageSection, TextInput,
    Wizard
} from '@patternfly/react-core';
import {useEffect, useState} from "react";
import {
    createAd,
    getAds,
    getOrganizations,
    getPresignedUrl,
    getWallet,
    NullComponent, registerOrganization, setupWallet,
    uploadToS3
} from "@app/utils/utils";
import {CampaignTypesComponent} from "@app/Campaigns/CampaignTypeComponent";
import {ChooseAdComponent} from "@app/Campaigns/ChooseAdComponent";
import {ChooseOrganizationComponent} from "@app/Campaigns/ChooseOrganizationComponent";
import {CampaignDatePicker} from "@app/Campaigns/CampaignDatePicker";
import { TimePicker } from '@patternfly/react-core';
import {AdTypesComponent} from "@app/Ads/AdTypeComponent";
import {RegisterAdComponent} from "@app/Ads/RegisterAdComponent";
import {UploadMediaComponent} from "@app/Ads/UploadMediaComponent";
import {createFieldInFormGroup} from "@app/utils/FormUtils";


export interface ISetupWalletComponentProps {
    triggerRefresh: Function;
}


const SetupWalletComponent: React.FunctionComponent<ISetupWalletComponentProps> = (props) => {

    const [isModalOpen, setModalOpen] = useState(false);
    const [walletSetup, setWalletSetup] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [addressCity, setAddressCity] = useState('');
    const [addressState, setAddressState] = useState('');
    const [addressZipCode, setAddressZipCode] = useState('');
    const [addressCountryCode, setAddressCountryCode] = useState('');
    const [validated, setValidated] = useState('');

    const resetForm = () => {

        setFirstName('');
        setMiddleName('');
        setLastName('');
        setPhoneNumber('');
        setAddressLine1('');
        setAddressLine2('');
        setAddressCity('');
        setAddressState('');
        setAddressZipCode('');
        setAddressCountryCode('');
    }

    function validateFields() {
        if (firstName == '' || lastName == '' || phoneNumber == ''
            || addressLine1 == ''
            || addressCity == '' || addressState == ''
        || addressZipCode == '' || addressCountryCode == ''){
            return false;
        }
        return true;
    }

    function createWallet(){

        if (validateFields()) {
            setupWallet({
                "firstName": firstName,
                "middleName": middleName,
                "lastName": lastName,
                "phoneNumber": phoneNumber,
                "address": {
                    "line1": addressLine1,
                    "line2": addressLine2,
                    "city": addressCity,
                    "state": addressState,
                    "zipCode": addressZipCode,
                    "countryCode": addressCountryCode
                }}).then(i => {

                if (undefined == i || null == i){
                    // @ts-ignore
                    setErrorMessage("Could not setup wallet")
                } else {
                    props.triggerRefresh(new Date());
                    handleModalToggle();
                }
            });
        } else {
            // @ts-ignore
            setValidated('error');
        }
    }

    const handleModalToggle = () => {
        resetForm();
        setModalOpen(!isModalOpen);
        props.triggerRefresh(new Date());
    };


    return (
            <React.Fragment>
                <Button variant="primary" onClick={handleModalToggle}>
                     Wallet Setup
                </Button>
                <Modal
                    variant={ModalVariant.small}
                    title="Setup your wallet"
                    description="Enter Information about your wallet"
                    isOpen={isModalOpen}
                    onClose={handleModalToggle}
                    actions={[
                        <Button
                            key="create"
                            variant="primary"
                            form="modal-with-form-form"
                            onClick={createWallet}
                        >
                            Confirm
                        </Button>,
                        <Button key="cancel" variant="link" onClick={handleModalToggle}>
                            Cancel
                        </Button>
                    ]}
                >

                    <Form >
                        {validated === 'error' && (
                            <FormAlert>
                                <Alert
                                    variant="danger"
                                    title="Fill out all required fields before continuing."
                                    aria-live="polite"
                                    isInline
                                />
                            </FormAlert>
                        )}

                        {createFieldInFormGroup("First name", "string", "First Name", "Must be a valid name", "firstName", firstName, setFirstName, validated )}
                        {createFieldInFormGroup("Middle name", "string", "Middle Name", "Must be a valid name", "middleName", middleName, setMiddleName, validated )}
                        {createFieldInFormGroup("Last name", "string", "Last Name", "Must be a valid name", "lastName", lastName, setLastName, validated )}
                        {createFieldInFormGroup("Phone number", "string", "Phone number", "Must be a valid name", "phoneNumber", phoneNumber, setPhoneNumber, validated )}

                        {createFieldInFormGroup("Address Line 1", "string", "Please enter billing address line 1", "Must be a valid street", "addressLine1", addressLine1, setAddressLine1, validated )}
                        {createFieldInFormGroup("Address Line 2", "string", "Please enter more information about your organizations address", "Must be valid text", "addressLine2", addressLine2, setAddressLine2, validated )}
                        {createFieldInFormGroup("City", "string", "Please enter your organizations city", "Must be valid city name", "city", addressCity, setAddressCity, validated )}
                        {createFieldInFormGroup("State", "string", "Please enter your organizations state", "Must be valid state", "state", addressState, setAddressState, validated )}
                        {createFieldInFormGroup("Zipcode", "string", "Please enter your organizations zipcode", "Must be valid zipcode", "zipcode", addressZipCode, setAddressZipCode, validated )}
                        {createFieldInFormGroup("Country", "string", "Please enter your organizations country", "Must be valid country", "state", addressCountryCode, setAddressCountryCode, validated )}

                    </Form>

                </Modal>
            </React.Fragment>
    );
}

export { SetupWalletComponent };