import * as React from 'react';
import { MonitoringIcon } from '@patternfly/react-icons'
import {getAds, getCampaigns, NullComponent} from "@app/utils/utils";
import {GenericListableComponent, ListViewType} from "@app/AppLayout/GenericList";
import {useEffect, useState} from "react";
import {Button, Modal, PageSection} from "@patternfly/react-core";
import { CreateCampaign } from './CreateCampaign';
import {useHistory} from "react-router";

export interface ICampaignsProps {}


const Campaigns: React.FunctionComponent<ICampaignsProps> = (props) => {

  const [campaignState, setCampaignState] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const history = useHistory();

  useEffect(() => {

      getAds().then(result => {
         if (result == undefined || result == null || result.length == 0){
             history.push('ads');
         }
      });
      getCampaigns().then(result => {
      setCampaignState(result);
    });
  }, [modalIsOpen, triggerRefresh]);

    return (
      <PageSection>
        <CreateCampaign triggerRefresh={setTriggerRefresh}/>
        {/*@ts-ignore*/ }
        <GenericListableComponent emptyIcon={MonitoringIcon}
                                theData={campaignState}
                                newItemComponent= {NullComponent}
                                modalOpenState={modalIsOpen}
                                setModelIsOpenFunction={setModalIsOpen}
                                listLayoutStyle={ListViewType.STACKED}
                                cardFooterFieldNames= {[]}
                                itemInFetchResponse=""
                                itemName="Campaign"
                                createItemText="New Campaign"
                                emptyListText="Campaigns are how you get your Ad in front of people. Create one after creating an Ad! Hi pandev:)"/>

        </PageSection>
  );
};

export { Campaigns };
