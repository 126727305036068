import * as React from 'react';
import {CubesIcon} from "@patternfly/react-icons";
import {getAds, getOrganizations, NullComponent} from "@app/utils/utils";
import {GenericListableComponent, ListViewType} from "@app/AppLayout/GenericList";
import {useEffect, useState} from "react";
import {PageSection} from "@patternfly/react-core";
import {CreateAdWizardModal} from "@app/Ads/CreateAdWizardModal";

const Ads: React.FunctionComponent<any> = (props) => {

  const [adState, setAdState] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  useEffect(() => {
    getAds().then(result => {
      setAdState(result);
    })
  }, [modalIsOpen, triggerRefresh]);


    //<CreateAd triggerRefresh={setTriggerRefresh}/>
  return (
      <PageSection>

        <CreateAdWizardModal triggerRefresh={setTriggerRefresh}/>
        <GenericListableComponent emptyIcon={CubesIcon}
                                newItemComponent={NullComponent}
                                theData={adState}
                                modalOpenState={modalIsOpen}
                                setModelIsOpenFunction={setModalIsOpen}
                                itemInFetchResponse=""
                                listLayoutStyle={ListViewType.SIDE_BY_SIDE}
                                itemName="Ads"
                                createItemText="Create Ad"
                                cardFooterFieldNames= {[{"label": "created by: ", "value": "$.creatorName"}, {"label": "updatedTime: ", "value": "$.updateDateTime", "type": "TIME"}]}
                                emptyListText="You don't have any Ads yet! Create one! :)"/>
      </PageSection>

  );
}

export { Ads };
