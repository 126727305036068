import * as React from 'react';
import {
    Card, CardBody, CardHeader, Dropdown,
    EmptyState,
    EmptyStateBody,
    EmptyStateIcon,
    EmptyStateVariant, Gallery, KebabToggle,
    PageSection,
    Title,
    CardActions, DropdownItem, CardExpandableContent, CardFooter, Button, CardTitle, Brand, CardHeaderMain
} from '@patternfly/react-core';
import {ReactComponent as VisaLogo} from '../../../assets/images/visa-svgrepo-com.svg'

import {loadStripe} from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import {FontAwesomeIcon, MonitoringIcon, MoneyCheckIcon} from "@patternfly/react-icons";
import {AddPaymentMethodComponent} from "@app/Wallet/AddPaymentMethodComponent";
import {CurrentBalanceComponent} from "@app/Wallet/CurrentBalanceComponent";
import {useEffect, useState} from "react";
import {getAds, getCampaigns, getOrganizations, getWallet, removePaymentMethodFromWallet} from "@app/utils/utils";
import {CheckoutForm} from "@app/Wallet/CheckoutForm";
import {CreateAdWizardModal} from "@app/Ads/CreateAdWizardModal";
import {SetupWalletComponent} from "@app/Wallet/SetupWalletComponent";
import {default as bolt} from "../../../assets/images/Lightning_Bolt_on_Circle.svg"
import {chart_global_label_text_anchor} from "@patternfly/react-tokens";


const stripePromise =  loadStripe(process.env["STRIPE_PUB_KEY"] + '');

const WalletComponent: React.FunctionComponent<any> = (props) => {


    const [wallet, setWallet] = useState({'currentBalance': '0'});
    const [isWalletSetup, setIsWalletSetup] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [forceRefresh, setForceRefresh] = useState();

    // load all items into `state`
    useEffect(() => {
        getWallet().then(result => {
            if (undefined == result || null == result){
                setIsWalletSetup(false);
            } else {
                setIsWalletSetup(true);
                setWallet(result);
            }
        })

    }, [forceRefresh]);

    const emptyState = () => {

        return <EmptyState variant={EmptyStateVariant.full}>
            <EmptyStateIcon icon={MoneyCheckIcon} />
            <Title headingLevel="h5" size="lg">
                Wallet
            </Title>
            <EmptyStateBody>
                Wallet not yet setup.

                {<br/>}
                {<br/>}
                <SetupWalletComponent triggerRefresh={setForceRefresh}/>
            </EmptyStateBody>
        </EmptyState>
    }

    const stripeWallet = () => {
        return (<Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>);
    }

    const currentWalletPrice = () => {
        if (wallet && wallet.currentBalance){
            return wallet.currentBalance;
        } else {
            return '0';
        }
    }



    const getCreditCardIconByType = (type) => {
        if (type === 'visa'){
            return "https://www.svgrepo.com/show/333620/visa.svg";
        } else if (type === 'amex') {
            return "https://www.svgrepo.com/download/266101/american-express.svg";
        } else if (type === "mastercard"){
            return "https://www.svgrepo.com/show/362011/mastercard.svg";
        } else {
            return "";
        }
    }


    const saveSelectedCard = e => {
        if (isSelected(e.currentTarget.getAttribute('data-type'))){
            setSelectedPaymentMethod("");
        } else {
            setSelectedPaymentMethod(e.currentTarget.getAttribute('data-type'));
        }
    }

    const isSelected = type => {
        return (selectedPaymentMethod == type) ? true : false;
    }

    const deletePaymentMethod = id => {
        /*removePaymentMethodFromWallet({
            "id": id
        }).then( e => {
            //setForceRefresh(new Date())
        });*/
    }

    const SimpleCard = (data) => (
        <span key={data.name}>

            <Card isRounded={true} key={data.id} data-type={data.id} isSelectableRaised isSelected={isSelected(data["id"])} isExpanded={isSelected(data["id"])} isCompact={false} isHoverable={true} onClick={saveSelectedCard}>
                <CardTitle component="h4"></CardTitle>
                <CardHeader>
                    <CardHeaderMain>
                        <React.Fragment>
                            <img src={getCreditCardIconByType(data.brand)} />
                        </React.Fragment>
                    </CardHeaderMain>
                    <CardActions>
                    </CardActions>
                </CardHeader>
                <CardBody>{data.expirationDate}          <div class={"d-flex justify-content-end"}><span className={"d-flex justify-content-end"}>**** **** **** {data.lastFour}</span></div></CardBody>
                <CardExpandableContent>
                    <CardBody><Button data-type={""} variant="danger" isSmall onClick={deletePaymentMethod(data.id)}> Delete</Button>{' '}</CardBody>
                </CardExpandableContent>
            </Card>
            <br />
        </span>

    );

    const displayPaymentMethods = (data) => {

        const map =  (data.map(e => {
            return SimpleCard(e);
        }));


        return (<Gallery hasGutter>
            {map}
        </Gallery>);
    }

    const hasWalletDisplay = () => {
        return (<React.Fragment>
            <AddPaymentMethodComponent />
            <br/><br/>
            <CurrentBalanceComponent balance={currentWalletPrice()}/>



            <br/>
                <React.Fragment>
                    <br/>
                    {wallet['registeredPaymentMethods'] && displayPaymentMethods(wallet['registeredPaymentMethods'])}
                </React.Fragment>

        </React.Fragment>)
    }

    const loadDisplay = () => {

        if (isWalletSetup){
            return hasWalletDisplay();
        } else {
            return emptyState();
        }
    }


  return (
    <PageSection>

        {loadDisplay()}


    </PageSection>
  );
}

export { WalletComponent };
