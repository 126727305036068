import * as React from "react";
import {
    Modal,
    ModalVariant,
    Button,
    Form,
    FormGroup,
    TextInput, FormAlert, Alert, FormHelperText,
} from "@patternfly/react-core";
import {useState} from "react";
import {registerOrganization} from "@app/utils/utils";
import { ExclamationCircleIcon } from "@patternfly/react-icons";

export interface IRegisterOrgProps {
    triggerRefresh: Function;
}

export const RegisterOrg: React.FunctionComponent<IRegisterOrgProps> = (props) => {
    const [isModalOpen, setModalOpen] = React.useState(false);
    const nameInputRef = React.useRef();



    const [name, setName] = useState();
    const [address_street, setAddressStreet] = useState();
    const [address_more_info, setAddressMoreInfo] = useState();
    const [address_city, setAddressCity] = useState();
    const [address_state, setAddressState] = useState();
    const [address_zipcode, setAddressZipcode] = useState();
    const [address_country, setAddressCountry] = useState();
    const [category, setCategory] = useState();
    const [validated, setValidated] = useState('default');

    const handleModalToggle = () => {
        setModalOpen(!isModalOpen);
    };

    function validateFields() {
        if (name == '' || address_street == ''
        || address_city == '' || address_zipcode == ''
        || address_country == '' || category == null){
            return false;
        }
        return true;
    }

    function saveOrganization(){

        if (validateFields()) {
            registerOrganization({
                "organizationName": name,
                "address": {
                    "street": address_street,
                    "moreInfo": address_more_info,
                    "city": address_city,
                    "state": address_state,
                    "zipcode": address_zipcode,
                    "country": address_country
                },
                "category": category
            }).then(i => {
                props.triggerRefresh()
                handleModalToggle()
            });
        } else {
            setValidated('error');
        }
    }
    React.useEffect(() => {
        if (isModalOpen && nameInputRef && nameInputRef.current) {
            (nameInputRef.current as unknown as HTMLInputElement).focus();
        }
    }, [isModalOpen]);


    const organizationTypes = [

    ]

    const fieldInForm = (label, type, helperText, helperTextInvalid, fieldId, bindingStateField, onChangeHandler) => {
        return <FormGroup
            label={label}
            type={type}
            helperText={
                <FormHelperText icon={<ExclamationCircleIcon />} isHidden={validated !== 'noval'}>
                    {helperText}
                </FormHelperText>
            }
            helperTextInvalid={helperTextInvalid}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            fieldId={fieldId}

        >
            <TextInput id={fieldId} value={bindingStateField} onChange={onChangeHandler}/>
        </FormGroup>
    }

    // @ts-ignore
    return (
        <React.Fragment>
            <Button variant="primary" onClick={handleModalToggle}>
                Register New Organization
            </Button>
            <Modal
                variant={ModalVariant.small}
                title="Onboard Organization"
                description="Enter information about your organization"
                isOpen={isModalOpen}
                onClose={handleModalToggle}
                actions={[
                    <Button
                        key="create"
                        variant="primary"
                        form="modal-with-form-form"
                        onClick={saveOrganization}
                    >
                        Confirm
                    </Button>,
                    <Button key="cancel" variant="link" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                ]}
            >


                <Form >
                    {validated === 'error' && (
                        <FormAlert>
                            <Alert
                                variant="danger"
                                title="Fill out all required fields before continuing."
                                aria-live="polite"
                                isInline
                            />
                        </FormAlert>
                    )}

                    {fieldInForm("Organization Name", "string", "Please enter your organization's name", "Must be a valid name", "name", name, setName )}

                    <label>
                        <p>Category</p>

                        <select name="category" onChange={e => setCategory(e.target.value)}>
                            <option value="BUSINESS"> Business</option>
                            <option value="PRIVATE">Private</option>
                        </select>
                    </label>

                    {fieldInForm("Street", "string", "Please enter your organizations street address", "Must be a valid street", "street", address_street, setAddressStreet )}
                    {fieldInForm("More Info", "string", "Please enter more information about your organizations address", "Must be valid text", "moreInfo", address_more_info, setAddressMoreInfo )}
                    {fieldInForm("City", "string", "Please enter your organizations city", "Must be valid city name", "city", address_city, setAddressCity )}
                    {fieldInForm("State", "string", "Please enter your organizations state", "Must be valid state", "state", address_state, setAddressState )}
                    {fieldInForm("Zipcode", "string", "Please enter your organizations zipcode", "Must be valid zipcode", "zipcode", address_zipcode, setAddressZipcode )}
                    {fieldInForm("Country", "string", "Please enter your organizations country", "Must be valid country", "state", address_country, setAddressCountry )}

                </Form>

            </Modal>
        </React.Fragment>
    );
};
