import {Card, CardBody, CardFooter, CardHeader, Gallery} from "@patternfly/react-core";
import * as React from "react";

interface ICampaignTypesComponentProps {
    setCampaignType: Function;
    campaign: string;
}
const CampaignTypesComponent: React.FunctionComponent<ICampaignTypesComponentProps> = (props) => {

    const saveType = e => {
        props.setCampaignType(e.currentTarget.getAttribute('data-type'));
    };

    const isSelected = type => {
        return (props.campaign == type) ? true : false;
    }

    return (
        <div>
        <span>
            <br/><br/>
            <Gallery>
            <Card data-type={"TimeOfDayCampaign"} isSelectableRaised isSelected={isSelected("TimeOfDayCampaign")} isCompact={true} isHoverable={true} onClick={saveType}>
                <CardHeader>Time and Location</CardHeader>
                <CardBody>Tell us what time you want your ad displayed.</CardBody>
                <CardFooter></CardFooter>
            </Card>
            <Card data-type={"GeoFenceCampaign"} isSelectableRaised isSelected={isSelected("GeoFenceCampaign")} isCompact={true} isHoverable={true} onClick={saveType}>
                <CardHeader>Geo Fence</CardHeader>
                <CardBody>Draw your route using our AdBuilder.</CardBody>
                <CardFooter></CardFooter>
            </Card>
            <Card data-type={"ProjectedReachCampaign"} isSelectableRaised isSelected={isSelected("ProjectedReachCampaign")} isCompact={true} isHoverable={true} onClick={saveType}>
                <CardHeader>Target Customer Reach</CardHeader>
                <CardBody>Tell us how many projected users you want to reach and we will do the hard work.</CardBody>
                <CardFooter></CardFooter>
            </Card>
            </Gallery>
        </span>
        </div>)
};
export {CampaignTypesComponent}