import * as React from "react";
import {ICreateCampaignProps} from "@app/Campaigns/CreateCampaign";
import {Button, Modal, ModalVariant} from "@patternfly/react-core";
import {useEffect, useState} from "react";
import {addPaymentMethodToWallet, getWallet} from "@app/utils/utils";
import {Elements, PaymentElement, useStripe, useElements} from "@stripe/react-stripe-js";
import SetupForm from "@app/Wallet/SetupForm";
import {loadStripe} from "@stripe/stripe-js";

const AddPaymentMethodComponent: React.FunctionComponent<any> = (props) => {

    const [isModalOpen, setModalOpen] = useState(false);
    const [paymentIntent, setPaymentIntent] = useState('');
    const stripePromise =  loadStripe(process.env["STRIPE_PUB_KEY"] + '');

    const handleModalToggle = () => {
        setModalOpen(!isModalOpen);
    };


    useEffect(() => {
        //if (isModalOpen) {
            addPaymentMethodToWallet(
                {'paymentTypes': ['card']}
            ).then(result => {
                setPaymentIntent(result.intentId);
            })
        //}

    }, [isModalOpen]);


    const appearance = {
        theme: 'stripe',
        labels: 'floating'
    };


    const DrawForm = () => {

        if (isModalOpen){

            let options = {
                clientSecret: paymentIntent,
                appearance: appearance
            };

            return (<Elements stripe={stripePromise} options = {options}>
                <SetupForm />
            </Elements>);
        } else {
            return <></>;
        }

    }

    return (<React.Fragment>
            <Button variant="primary" onClick={handleModalToggle}>
                Add new Payment Method
            </Button>

            <Modal
                variant={ModalVariant.small}
                title="Add Payment Method to Wallet"
                isOpen={isModalOpen}
                onClose={handleModalToggle}
            >

                <DrawForm />


            </Modal>
        </React.Fragment>
    )
}

export {AddPaymentMethodComponent}