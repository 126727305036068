import React from 'react';
import {DatePicker, TimePicker} from '@patternfly/react-core';

export const CampaignDatePicker: React.FunctionComponent = () => {
    const dateFormat = (date: Date) =>
        date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');


    const StartingTime = (props) => {
        return (
            <TimePicker is24Hour minTime="5:00" maxTime="22:00" placeholder="14:00" />
        );
    }

    const EndingTime = (props) => {
        return (
            <TimePicker is24Hour minTime="5:00" maxTime="22:00" placeholder="14:00" />
        );
    }

    const getNextWeekString = () => {
        return dateFormat(nextweek());
    }

    function nextweek(){
        var today = new Date();
        var nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);
        return nextweek;
    }

    const dateParse = (date: string) => {
        const split = date.split('-');
        if (split.length !== 3) {
            return new Date();
        }
        const month = split[0];
        const day = split[1];
        const year = split[2];
        return new Date(`${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00`);
    };



    return (
        <React.Fragment>
            <DatePicker value={getNextWeekString()} placeholder="MM-DD-YYYY" dateFormat={dateFormat} dateParse={dateParse} />
            <br/><br/>
            <StartingTime/>
            <EndingTime/>
        </React.Fragment>
    );
};
