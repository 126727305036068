import * as React from 'react';
import {BuildingIcon, MonitoringIcon} from "@patternfly/react-icons";
import {getCampaigns, getOrganizations} from "@app/utils/utils";
import {GenericListableComponent, ListViewType} from "@app/AppLayout/GenericList";
import {useEffect, useState} from "react";
import {RegisterOrg} from "@app/Organizations/RegisterOrg";
import {PageSection} from "@patternfly/react-core";


const Organizations: React.FunctionComponent<any> = (props) => {

  const [organizationsState, setOrganizationState] = useState();
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    getOrganizations().then(result => {
      setOrganizationState(result);
    });
  }, [modalIsOpen, triggerRefresh]);

  const NullComponent= () => {
    return null;
  };

  return (

      <PageSection>
        <RegisterOrg triggerRefresh={setTriggerRefresh}/>
        <GenericListableComponent emptyIcon={BuildingIcon}
                                theData={organizationsState}
                                newItemComponent={NullComponent}
                                modalOpenState={modalIsOpen}
                                setModelIsOpenFunction={setModalIsOpen}
                                listLayoutStyle={ListViewType.STACKED}
                                itemInFetchResponse = "organizations"
                                itemName="Organizations"
                                cardFooterFieldNames= {[{"label": "", "value": "$.address.city"}]}
                                createItemText="Onboard an Organization"
                                emptyListText="We don't have any of your organizations registered with us."/>
        </PageSection>
  );
}

export { Organizations };
