export class InvalidAuthException extends Error {
    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, InvalidAuthException.prototype)
    }

    getErrorMessage() {
        return "Invalid username or password"
    }

}