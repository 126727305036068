import React, {useState} from 'react';
import {Card, CardBody, CardFooter, CardHeader, Gallery} from "@patternfly/react-core";
export interface ICurrentBalanceComponentProps {
    balance: String;
}

const CurrentBalanceComponent : React.FunctionComponent<ICurrentBalanceComponentProps> = (props) => {

    const [balance, setBalance] = useState(100);

    const cardFlip = () => {
        console.log('flipping the card');
    }

    return (
        <React.Fragment>
            <Gallery hasGutter>
            <Card isSelectableRaised isCompact={true} isHoverable={true} onClick={cardFlip}>
                <CardHeader>Slot Balance</CardHeader>
                <CardBody>{props.balance}</CardBody>
                <CardFooter></CardFooter>
            </Card>
            </Gallery>
        </React.Fragment>
    );
};
export {CurrentBalanceComponent}