import * as React from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    EmptyState, EmptyStateBody, EmptyStateIcon,
    EmptyStateVariant,
    Gallery,
    PageSection,
    Title
} from '@patternfly/react-core';
import {getAds, getCampaigns, getOrganizations} from "@app/utils/utils";
import {useEffect, useState} from "react";
import {BuildingIcon} from "@patternfly/react-icons";
import {useHistory} from "react-router";

const Dashboard: React.FunctionComponent<any> = (props) => {

   const [organizationState, setOrganizationState] = useState();
   const [adsState, setAdsState] = useState();
   const [campaignsState, setCampaignsState] = useState();
   const history = useHistory();



    // load all items into `state`
    useEffect(() => {

        getAds().then(result => {
            setAdsState(result);
        })

        getOrganizations().then(result => {
            setOrganizationState(result);
            let numberOfOrganizations = result!=undefined ? result["organizations"].length : 0;
            if (numberOfOrganizations == 0) {
                history.push("/organizations")
            }
        })

        getCampaigns().then(result => {
            setCampaignsState(result);
        });

    }, []);



    function cardFlip(){}

    const SimpleCard = (data) => {

        if (data == undefined){
            return <></>;
        }
        return <React.Fragment>
            <Card isCompact={true} isHoverable={true} onClick={cardFlip}>
                <CardHeader className={'text-3xl'}><h1>{data.header}</h1></CardHeader>
                <CardBody>{data.body}<br/></CardBody>
                <CardFooter className={'center'}>{data.footer}</CardFooter>
            </Card>
            <br/>
        </React.Fragment>
    };


    const OrganizationTile = () => {
        let numberOfOrganizations = organizationState!=undefined ? organizationState["organizations"].length : 0;
        return SimpleCard({
            "header": numberOfOrganizations,
            "body": "",
            "footer": "organizations"
        })
    }

    const AdsTile = () => {
        const numberOfAds = adsState != undefined ? adsState.length : 0;
        return SimpleCard({
                "header": numberOfAds,
                "body": "",
                "footer": "ads"
            })
    }

    const CampaignsTile = () => {
        const numberOfCampaigns = campaignsState != undefined ? campaignsState.length : 0;
        return SimpleCard({
            "header": numberOfCampaigns,
            "body": "",
            "footer": "campaigns"
        })
    }

    const handleJumpToOrganizations = () => {
        history.push("/organizations")
    }

    const NeedToSetupOrganization = () => {


        return <EmptyState onClick={handleJumpToOrganizations} variant={EmptyStateVariant.full}>
            <EmptyStateIcon icon={BuildingIcon} />
            <Title headingLevel="h5" size="lg">
                Go to Organizations to register your business!
            </Title>
            <EmptyStateBody>
            </EmptyStateBody>
        </EmptyState>
    }

    const DashboardTile = () => {

        const numberOfOrganizations = organizationState!=undefined ? organizationState["organizations"].length : 0;

        if (numberOfOrganizations == 0){
            return <NeedToSetupOrganization />
        }

        return (<Gallery hasGutter>
            {OrganizationTile()}
            {AdsTile()}
            {CampaignsTile()}
        </Gallery>)
    }

  return (
    <PageSection>
        <React.Fragment>
            <DashboardTile />
        </React.Fragment>

    </PageSection>
  );
}

export { Dashboard };
