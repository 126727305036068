import * as React from 'react';
import '@app/Organizations/organizations.css';
import {
    Button,
    Modal,
    ModalVariant,
    TextInput,
    Wizard
} from '@patternfly/react-core';
import {useState} from "react";
import {getPresignedUrl, uploadToS3} from "@app/utils/utils";
import {ChooseOrganizationComponent} from "@app/Campaigns/ChooseOrganizationComponent";
import {RegisterAdComponent} from "@app/Ads/RegisterAdComponent";
import {UploadMediaComponent} from "@app/Ads/UploadMediaComponent";


export interface ICreateAdProps {
    triggerRefresh: Function;
}

const SetNameComponent = (props) => {
    return (
        <TextInput value={props.name} onChange={props.setName} aria-label={"Set Name of Ad"}/>
    );
}

const CreateAdWizardModal: React.FunctionComponent<ICreateAdProps> = (props) => {

    const [isModalOpen, setModalOpen] = React.useState(false);
    const [name, setName] = useState('');
    const [ad_id, setAdId] = useState('');
    const [org_id, setOrgId] = useState();
    const [file, setFile] = useState();
    const [configMap, setConfigMap] = useState();
    const [currentStep, setCurrentStep] = useState(1);

    const organizationNotSetDisplay = () => {
        return <h1>Please choose an Organization in Step 2</h1>;
    }

    const nameNotSetDispplay = () => {
        return <h1>Please set name of campaign in Step 1</h1>;
    }

    const resetForm = () => {
        setName('');
        setOrgId('');
        setFile(null);
    }

    const ReviewData = () => {

        if (name == undefined || name == null || name == ""){
            return nameNotSetDispplay();
        }


        getPresignedUrl(org_id, ad_id, file.name.trim()).then( data => {
            uploadToS3(data['url'], file);
        })


        return (<React.Fragment>
            <h1>Name: {name}</h1>
            <h1>org: {org_id}</h1>
            <h1>file: {file.name}</h1>
        </React.Fragment>)
    }

    const onNext = (id) => {
        setCurrentStep(id);
    }

    const canEditAdDetails = () => {
        return (ad_id != null || ad_id != undefined || ad_id != "");
    }

    const steps = [
        { id: 1, name: 'Set Name', canJumpTo: false, component: <p><SetNameComponent name={name} setName={setName}/></p>, onNext: ''},
        { id: 2, name: 'Choose Organization', canJumpTo: false, component: <p><ChooseOrganizationComponent chosenOrganizationId={org_id} setChosenOrganizationId={setOrgId}/></p>, onNext: ''},
        { id: 3, name: 'Register Ad', canJumpTo: false, component: <RegisterAdComponent adId={ad_id} setAdId={setAdId} name={name} organizationId={org_id} triggerRefresh={props.triggerRefresh}/> },
        { id : 4, name: 'Upload Media', canJumpTo: false, component: <UploadMediaComponent setFile={setFile} /> },
        { id: 5, name: 'Review', component: <ReviewData/>, nextButtonText: 'Finish', isFinishedStep:true }
    ];

    const handleModalToggle = () => {
        resetForm();
        setModalOpen(!isModalOpen);
        props.triggerRefresh(true);
    };


    return (
        <React.Fragment>
            <Button variant="primary" onClick={handleModalToggle}>
                Create a New Ad
            </Button>
            <Modal
                variant={ModalVariant.large}
                title="Create a New Ad"
                //description="Enter information about your Ad"
                isOpen={isModalOpen}
                onClose={handleModalToggle}
            >
                <Wizard navAriaLabel={"Create Ad steps"}
                        mainAriaLabel={"Create Ad steps content"}
                        steps={steps} height={400} onClose={handleModalToggle}
                />
            </Modal>
        </React.Fragment>
    );
}

export { CreateAdWizardModal };