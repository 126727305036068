import * as React from 'react';
import {Card, CardBody, CardFooter, CardHeader, Gallery, PageSection, Title} from '@patternfly/react-core';
import {getAds, getOrganizations} from "@app/utils/utils";
import {useEffect, useState} from "react";

const Analytics: React.FunctionComponent<any> = (props) => {


  return (
    <PageSection>
        <React.Fragment>
            <Gallery>

            </Gallery>
        </React.Fragment>

    </PageSection>
  );
}

export { Analytics };
