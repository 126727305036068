import * as React from "react";
import {createAd} from "@app/utils/utils";
import {useEffect, useState} from "react";
import { Spinner } from '@patternfly/react-core';

interface IRegisterAdComponentProps {
    name: string;
    organizationId: number;
    adId: string;
    setAdId: Function;
    triggerRefresh: Function;
}

const RegisterAdComponent: React.FunctionComponent<IRegisterAdComponentProps> = (props) => {

    const [complete, setComplete] = useState(false);

    function saveAd(name, organizationId){
        createAd({
            "name": name,
            "tags": ["default"],
            "organizationId": organizationId
        }).then( i => {
            props.setAdId(i.id);
            props.triggerRefresh(new Date());
            setComplete(true);
        });
    }


    useEffect(() => {
        saveAd(props.name, props.organizationId)
    }, []);


    const showDisplay = (complete) => {
        if (complete == false){
            return <React.Fragment>
                <Spinner size="xl"/>
            </React.Fragment>
        } else {
            return <span>completed</span>
        }
    }

    return (
        <div>
            {showDisplay(complete)}
        </div>)
};
export {RegisterAdComponent}
