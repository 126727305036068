import {Card, CardBody, CardFooter, CardHeader, Gallery} from "@patternfly/react-core";
import * as React from "react";
import {useEffect} from "react";
import {getAds, getOrganizations} from "@app/utils/utils";

interface IChooseOrganizationComponentProps {
    setChosenOrganizationId: Function;
    chosenOrganizationId: string;
}

const ChooseOrganizationComponent: React.FunctionComponent<IChooseOrganizationComponentProps> = (props) => {

    const [organizations, setOrganizations] = React.useState();

    // load all organization into `state`
    useEffect(() =>
    {
        getOrganizations().then(profile => {
            setOrganizations(profile.organizations);

            if (profile.organizations.length == 1) {
                props.setChosenOrganizationId(profile.organizations[0].id);
            }
        })
    }, []);

    const saveOrganizationId = e => {
        props.setChosenOrganizationId(e.currentTarget.getAttribute('data-organization-id'));
    };

    const isSelected = orgId => {
        return (props.chosenOrganizationId == orgId) ? true : false;
    };

    const cardFlip = (data) => {
        console.log(data);
    };

    function getListOfSimpleCards() {

        if (organizations == null || organizations == undefined){
            return <></>;
        }
        return organizations.map(data => {
            return SimpleCard(data)
        })
    }

    const SimpleCard = (data) => (
        <span key={data.id}>
            <Card data-organization-id={data.id} isSelectableRaised isSelected={isSelected(data.id)} isCompact={true} isHoverable={true} onClick={saveOrganizationId}>
                <CardHeader>{data.organizationName}</CardHeader>
                <CardFooter>{data.footer}</CardFooter>
            </Card>
            <br />
        </span>
    );

    return (
        <React.Fragment>
            {getListOfSimpleCards()}
        </React.Fragment>
    )
};
export {ChooseOrganizationComponent}